import React from 'react'
import { withUnpublishedPreview } from 'gatsby-source-prismic'

import Product from '../templates/Product'
import Category from '../templates/Category'
import Homepage from '../templates/Homepage'
import Longform from '../templates/Longform'
import Subcategory from '../templates/Subcategory'

const NotFound = () => {
  return (
    <div>
      <div>NOT FOUND</div>
    </div>
  )
}

export default withUnpublishedPreview(NotFound, {
  templateMap: {
    category: Category,
    homepage: Homepage,
    longform: Longform,
    product: Product,
    subcategory: Subcategory,
  },
})
