import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import onPageRender from '../hocs/onPageRender'
import CategoryIntro from '../components/CategoryIntro/CategoryIntro'
import Container from '../components/Container/Container'
import Slices from '../components/Slices/Slices'
import SectionTitle, {
  TITLE_TYPES,
} from '../components/SectionTitle/SectionTitle'
import SubcategoryNav from '../components/SubcategoryNav/SubcategoryNav'
import ProductGridBackground from '../components/ProductGridBackground/ProductGridBackground'
import Seo from '../components/Seo/Seo'

const getNonEmptySubcategories = subcategories =>
  subcategories.filter(({ subcategory: { document } }) => !!document)

const getAllSubcategoryBodies = subcategories =>
  subcategories.reduce(
    (
      bodyArr,
      {
        subcategory: {
          document: {
            data: { body },
          },
        },
      }
    ) => [...bodyArr, ...body],
    []
  )

const Category = ({ data, addVariantToCart }) => {
  const {
    allShopifyProduct,
    prismicCategory: {
      _previewable,
      data: {
        title: { text: title },
        description: { text: description },
        body: dataBody,
        subcategories: subcategoryData,
      },
      fields: { pagePath } = {},
    },
  } = data

  const subcategories = getNonEmptySubcategories(subcategoryData)
  const subcategoryBodies = getAllSubcategoryBodies(subcategories)

  // TODO: remove duplicates
  const body = [...dataBody, ...subcategoryBodies]

  const isPreview = !!_previewable

  return (
    <>
      <Seo title={title} description={description} />
      <Container>
        <CategoryIntro
          title={title}
          description={description}
          pagePath={pagePath}
          nav={
            !isPreview && subcategories.length > 0 ? (
              <SubcategoryNav
                categoryTitle={title}
                categoryPagePath={pagePath}
                subcategories={subcategories}
              />
            ) : null
          }
        />
      </Container>
      <Container>
        <SectionTitle text={title} type={TITLE_TYPES.HANDWRITTEN_ALPHA} />
        <Slices
          body={body}
          allShopifyProduct={allShopifyProduct}
          addVariantToCart={addVariantToCart}
        />
      </Container>
      <ProductGridBackground />
    </>
  )
}

Category.propTypes = {
  data: PropTypes.object.isRequired,
  addVariantToCart: PropTypes.func.isRequired,
}

export default onPageRender(Category)

export const query = graphql`
  query($id: String!) {
    prismicCategory(id: { eq: $id }) {
      ...PrismicCategoryData
      fields {
        pagePath
      }
    }
    allShopifyProduct {
      edges {
        node {
          shopifyId
          variants {
            sku
            price {
              amount
            }
            shopifyId
            availableForSale
          }
        }
      }
    }
  }
`
